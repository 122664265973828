import { useContext } from 'react'
import { DataContext } from '@/data_provider'
import { TIME_OFFSETS } from '@/constants/client_settings'
import { formatTime } from '@/utils'
import styles from './Solar.module.css'

type SvgComponentProps = {
  sunrise: string
  sunset: string
  civilDawn: string
  civilDusk: string
  nauticalDawn: string
  nauticalDusk: string
  astroDawn: string
  astroDusk: string
}

const angle = 20 // Angle in degrees
const radius = 100 // Radius of the circle

// Calculate the endpoint coordinates of the arc
const x = radius * Math.cos(angle * Math.PI / 180)
const y = -radius * Math.sin(angle * Math.PI / 180)

const nightX = radius * Math.cos(angle * 5 * Math.PI / 180)
const nightY = -radius * Math.sin(angle * 5 * Math.PI / 180)

console.log(x, y, nightX, nightY)

const slicePath = 
  "M0,0" +  // Move to center of circle
  "L100,0" +  // Draw line to edge of circle
  `A${radius},${radius} 0 0,0 ${x},${y}` +  // Draw rounded arc with large arc flag set to 0 for convex arc
  "Z"  // Close path

const nightPath = 
  "M0,0" +  // Move to center of circle
  "L100,0" +  // Draw line to edge of circle
  `A${radius},${radius} 0 0,0 ${nightX},${nightY}` +
  "Z"  // Close path

const SvgComponent = ({
  sunrise,
  sunset,
  civilDawn,
  civilDusk,
  nauticalDawn,
  nauticalDusk,
  astroDawn,
  astroDusk
}: SvgComponentProps) => {
  const sliceDefs = [
    {
      text: sunrise,
      angle: 0,
      bgColor: "#FFC044",
      fgColor: "#000"
    }, {
      text: civilDawn,
      angle: 20,
      bgColor: "#00B3FF",
      fgColor: "#000"
    }, {
      text: nauticalDawn,
      angle: 40,
      bgColor: "#16397D",
      fgColor: "#FFF"
    }, {
      text: astroDawn,
      angle: 60,
      bgColor: "#015",
      fgColor: "#FFF"
    }, {
      text: sunset,
      angle: 200,
      bgColor: "#FFC044",
      fgColor: "#000"
    }, {
      text: civilDusk,
      angle: 180,
      bgColor: "#00B3FF",
      fgColor: "#000"
    }, {
      text: nauticalDusk,
      angle: 160,
      bgColor: "#16397D",
      fgColor: "#FFF"
    }, {
      text: astroDusk,
      angle: 140,
      bgColor: "#015",
      fgColor: "#FFF"
    }
  ]
  const slices = [
    {
      text: "",
      angle: 140,
      path: nightPath,
      bgColor: "#015",
      fgColor: "#FFF"
    },
    ...sliceDefs.map(slice => ({
      ...slice,
      path: slicePath
    }))
  ]
  return (
    <div className={styles.container}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-100 -65 200 190">
      {slices.map((slice, index) => (
        <g key={index} transform={`rotate(${slice.angle})`}>
          <path fill={slice.bgColor} d={slice.path} />
          <text
            fill={slice.fgColor}
            fontSize={8}
            letterSpacing="0em" 
            style={{ 
              whiteSpace: "pre"
            }}
            transform={`rotate(${slice.angle > 90 ? 170 : -10})`}
          >
            <tspan x={slice.angle > 90 ? -92 : 59} y={3}>
              {slice.text}
            </tspan>
          </text>
        </g>
      ))}
      <text
        xmlSpace="preserve"
        fill="#FFC700"
        fontSize={7}
        letterSpacing="0em"
        style={{
          whiteSpace: "pre",
          textAlign: "center"
        }}
      >
        <tspan x={-17} y={-25}>
          {"DAYLIGHT"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        fill="#FFF"
        fontSize={7}
        letterSpacing="0em"
        style={{
          whiteSpace: "pre",
          textAlign: "center",
          fontWeight: "400"
        }}
      >
        <tspan x={-27} y={67}>
          {"ASTROLOGICAL"}
        </tspan>
        <tspan x={-17} y={77}>
          {"TWILIGHT"}
        </tspan>
      </text>
    </svg>
  </div>
    )
}

const Solar = () => {
  const { data: { weather: { timeData }} } = useContext(DataContext)
  if (!timeData) return null
  const { sunrise, sunset } = timeData
  return (
    <div className='svg-image'>
      <SvgComponent
        sunrise={formatTime(sunrise)}
        sunset={formatTime(sunset)}
        civilDawn={formatTime(sunrise.plus({ minutes: TIME_OFFSETS.civilDawn }))}
        civilDusk={formatTime(sunset.plus({ minutes: TIME_OFFSETS.civilDusk }))}
        nauticalDawn={formatTime(sunrise.plus({ minutes: TIME_OFFSETS.nauticalDawn }))}
        nauticalDusk={formatTime(sunset.plus({ minutes: TIME_OFFSETS.nauticalDusk }))}
        astroDawn={formatTime(sunrise.plus({ minutes: TIME_OFFSETS.astroDawn }))}
        astroDusk={formatTime(sunset.plus({ minutes: TIME_OFFSETS.astroDusk }))}
      />
    </div>
  )
}

export default Solar
