import { createContext, useReducer, ReactNode, Context } from "react"
import { fetchWeatherApi } from 'openmeteo'
import { useFetch } from '@/hooks/useFetch'
import { GlobalData } from "@/models"
import { DateTime } from "luxon"
import { METEO_REFRESH_INTERVAL } from "./api/_helpers/settings"
import { WAIKIKI_COORDS } from "./constants/client_settings"

type AppState = {
  data: GlobalData
}

const DEFAULT_GLOBAL_DATA: GlobalData = {
  weather: {
    temperature: null,
    precipitation: null,
    uvIndex: null,
    wind: null,
    timeData: null
  }
}

const url = "https://api.open-meteo.com/v1/forecast"

const params = {
	"latitude": WAIKIKI_COORDS.lat,
	"longitude": WAIKIKI_COORDS.lon,
	"hourly": [
    "temperature_2m",
    "apparent_temperature",
    "uv_index",
    "precipitation",
    "wind_gusts_10m"
  ],
  "daily": [
    "sunrise",
    "sunset"
  ],
  "current": [
    "is_day",
    "weather_code",
    "temperature_2m",
    "apparent_temperature",
    "precipitation",
    "wind_gusts_10m"
  ],
  "temperature_unit": "fahrenheit",
  "forecast_hours": 24,
  "forecast_days": 1,
  "timeformat": "unixtime",
  "timezone": "auto"
}

export const DataContext: Context<AppState> = createContext<AppState>({
  data: DEFAULT_GLOBAL_DATA
})

const initialState = {
  data: DEFAULT_GLOBAL_DATA
}

const reducer = (
  state: AppState,
  action: {payload: GlobalData, type: string}
) : AppState => {
  switch (action.type) {
    case "SET_DATA":
      return {
        ...state,
        data: action.payload
      };
    default:
      return state
  }
}

// Helper function to form time ranges
const range = (start: number, stop: number, step: number) =>
	Array.from({ length: (stop - start) / step }, (_, i) => start + i * step)

export const DataProvider = ({ children } : { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const meteoFetcher = async () => {
    return fetchWeatherApi(url, params).then((responses) => {
      const response = responses[0];

      // const utcOffsetSeconds = response.utcOffsetSeconds();
      // const timezone = response.timezone();
      // const timezoneAbbreviation = response.timezoneAbbreviation();
      // const latitude = response.latitude();
      // const longitude = response.longitude();

      const hourly = response.hourly()!
      const daily = response.daily()!
      const current = response.current()!

      const hourlyData = {
        time: range(Number(hourly.time()), Number(hourly.timeEnd()), hourly.interval()).map(
          (t) => new Date(t * 1000)
        ),
        temperature: hourly.variables(0)!.valuesArray()!,
        feelsLike: hourly.variables(1)!.valuesArray()!,
        uvIndex: hourly.variables(2)!.valuesArray()!,
        precipitation: hourly.variables(3)!.valuesArray()!,
        wind: hourly.variables(4)!.valuesArray()!
      }
      const dailyData = {
        time: range(Number(daily.time()), Number(daily.timeEnd()), daily.interval()).map(
          (t) => new Date(t * 1000)
        ),
        sunrise: Number(daily.variables(0)!.valuesInt64(0)),
        sunset: Number(daily.variables(1)!.valuesInt64(0))
      }
      const currentWeather = {
        isDay: !!current.variables(0)!.value(),
        weatherCode: current.variables(1)!.value(),
        temperature: Math.round(current.variables(2)!.value()),
        feelsLike: Math.round(current.variables(3)!.value()),
        precipitation: current.variables(4)!.value(),
        wind: current.variables(5)!.value()
      }
      const temperature = [{
        id: "temperature",
        data: hourlyData.time
          .map((t, i) => ({
            x: t.getTime(),
            y: hourlyData.temperature[i]
          }))
      }, {
        id: "feelsLike",
        data: hourlyData.time
          .map((t, i) => ({
            x: t.getTime(),
            y: hourlyData.feelsLike[i]
          }))
      }]
      const precipitation = hourlyData.time.map((t, i) => ({
        time: t.getTime(),
        value: hourlyData.precipitation[i] + 0.01
      }))
      const uvIndex = hourlyData.time.map((t, i) => ({
        time: t.getTime(),
        value: hourlyData.uvIndex[i]
      }))
      const wind = hourlyData.time.map((t, i) => ({
        time: t.getTime(),
        value: hourlyData.wind[i]
      }))
      const timeData = {
        sunrise: DateTime.fromSeconds(dailyData.sunrise),
        sunset: DateTime.fromSeconds(dailyData.sunset)
      }
      const weatherData = {
        currentWeather,
        temperature,
        precipitation,
        uvIndex,
        wind,
        timeData
      }
      dispatch({ type: "SET_DATA", payload: { weather: weatherData} })
    })
  }
  useFetch(meteoFetcher, { refreshInterval: METEO_REFRESH_INTERVAL })
  const value = { data: state.data }  
  if (state.data === DEFAULT_GLOBAL_DATA) return null
  return  <DataContext.Provider value={value}>{children}</DataContext.Provider>
}
